import { mapActions, mapGetters } from 'vuex';
import techCheckEnum from '../../../enums/techCheck';
const { get } = require('../../../helpers/cookie');

export default {
	name: 'ComputerDiagnostics',
	i18n: require('./i18n').default,
	data: function () {
		return {
			selected: {
				items: [
					{
						system: '',
						error: ''
					},
					{
						system: '',
						error: ''
					}
				],
				computerDiagnostic: {
					option: techCheckEnum.COMPUTER_DIAGNOSTICS_OPTIONS_VALUES
				},
				summary: ''
			},
			elCompDiagnItems: techCheckEnum.ELECTRIC_COMPUTER_DIAGNOSTICS_ITEMS,
			elCompDiagnOptions: techCheckEnum.ELECTRIC_COMPUTER_DIAGNOSTICS_OPTIONS,
			testCookie: '',
		};
	},
	computed: {
		...mapGetters({
			langPrefix: 'lang/prefix',
			autotestData: 'TechnicalCheck/autotestData'
		})
	},
	props: ['computerDiagnostics', 'isElectric', 'isVinHistoryRequest'],
	created() {
		if (this.computerDiagnostics) {
			this.selected = this.computerDiagnostics;
		}
	},
	mounted() {
		this.testCookie = get('testElectricComputerDiagnostic', document.cookie);
	},
	methods: {
		addItem: function () {
			this.selected.items.push({
				system: '',
				error: ''
			});
		},
		deleteItem: function (index) {
			this.selected.items.splice(index, 1);
		}
	},
	watch: {
		'selected': {
			handler() {
				this.$parent.$emit('changeComputerDiagnostics', this.selected);
			},
			deep: true
		}
	}
};
